




import { merge } from 'lodash'
import {
  Chart,
  ChartType,
  ChartData,
  ChartOptions,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Legend,
  Title,
  Tooltip,
} from 'chart.js'
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Legend,
  Title,
  Tooltip
)

export default defineComponent({
  name: 'SiteChart',

  props: {
    type: {
      type: String as PropType<ChartType>,
      required: true,
    },

    data: {
      type: Object as PropType<ChartData>,
      default: () => ({} as ChartData),
    },

    options: {
      type: Object as PropType<ChartOptions>,
      default: () => ({} as ChartOptions),
    },
  },

  setup(props) {
    let chart: Chart | null = null
    const canvas = ref<HTMLCanvasElement | null>(null)
    const defaultOptions: ChartOptions = {
      responsive: true,
    }

    onMounted(() => {
      if (!canvas.value) return
      chart = new Chart(canvas.value, {
        type: props.type,
        data: props.data,
        options: merge(defaultOptions, props.options),
      })
    })

    watch(
      () => props.data,
      (newValue) => {
        if (!chart) return
        chart.data = newValue
        chart.update()
      },
      {
        deep: true,
      }
    )

    return {
      canvas,
    }
  },
})
