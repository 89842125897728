import { AxiosInstance } from 'axios'
import { api } from '../../client'
import { serviceUrl } from '../../utils'

const controller = serviceUrl('dashboard')

function createDashboardService(client: AxiosInstance) {
  return {
    get(params: DashboardSelectModel) {
      return client.get<DashboardViewModel>(controller.action('get'), {
        params,
      })
    },
  }
}
export const dashboardService = createDashboardService(api.base)

export interface DashboardSelectModel {
  startingDate: string | null
  endingDate: string | null
}

export interface DashboardQuoteViewModel {
  name: string
  total: number
}

export interface DashboardTotalViewModel {
  quoteTotal: number
  contactTotal: number
  manometerValue: number
  manometerIncrementBy: number
}

export interface QuoteViewModel {
  quoteId: string
  quoteKey: number
  proposedTechnology: string | null
  wasteWaterFlowToBeTreated: string | null
  biochemicalOxygenDemandConcentration: string | null
  complianceWithTheStandard: string | null
  requiredArea: string | null
  investmentBaseBudget: string | null
  waterFlowToBeTreatedPerDay: string | null
  complianceWithStandard: string | null
  fieldOfBusiness: string | null
  residentialType: string | null
  rangeOfPeople: string | null
  commerceType: string | null
  waterType: string | null
  sectorType: string | null
  typeOfBird: string | null
  animalQuantity: string | null
  tonsOfChickensPerDay: string | null
  siteType: string | null
  animalType: string | null
  numberOfShifts: string | null
  shiftEmployerAmount: string | null
  waterConsumptionPerDay: string | null
  email: string
  createdBy: string
  creationDate: Date | string
}

export interface DashboardViewModel {
  totalViewModel: DashboardTotalViewModel | null
  quoteTotalList: DashboardQuoteViewModel[] | null
  quoteList: QuoteViewModel[] | null
}
