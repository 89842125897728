var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"flex flex-col space-y-8"},[_c('BaseBox',{staticClass:"grid gap-4 lg:grid-cols-2 xl:grid-cols-4"},[_c('BaseBox',{staticClass:"lg:col-start-2 xl:col-start-4"},[_c('BaseFieldLabel',{attrs:{"html-for":"date-filter","label":"Rango de fecha"}}),_c('BaseSelect',{attrs:{"id":"date-filter","options":_vm.dashboardDateFilterOptions,"show-labels":false,"label":"title","track-by":"key"},model:{value:(_vm.dashbaordFilterOption),callback:function ($$v) {_vm.dashbaordFilterOption=$$v},expression:"dashbaordFilterOption"}})],1)],1),_c('BaseBox',{staticClass:"grid gap-4"},[_c('BaseHeading',{staticClass:"text-lg font-bold",attrs:{"level":2}},[_vm._v(" Visión general ")]),_c('BaseBox',{staticClass:"grid gap-4 lg:grid-cols-2 xl:grid-cols-4"},_vm._l((_vm.dashboardCards),function(card){return _c('BaseBox',{key:card.key,staticClass:"bg-white dark:bg-gray-800 rounded shadow p-6 flex space-x-4 items-center"},[_c('BaseBox',{staticClass:"py-4 px-5 rounded border-primary-50 bg-primary-50 text-primary-700 dark:border-primary-700 dark:bg-primary-700 dark:text-primary-100 dark:border-opacity-70 dark:bg-opacity-70"},[_c('BaseIcon',{attrs:{"name":card.icon}})],1),_c('BaseBox',{staticClass:"grid"},[_c('BaseText',{staticClass:"text-gray-600 dark:text-gray-400"},[_vm._v(" "+_vm._s(card.title)+" ")]),_c('BaseText',{staticClass:"text-2xl text-gray-900 dark:text-gray-100 font-semibold"},[_c('NumberAnimated',{attrs:{"value":card.value,"abbreviate":true}})],1)],1)],1)}),1)],1),_c('BaseBox',{staticClass:"bg-white dark:bg-gray-800 rounded shadow p-6"},[_c('SiteChart',{attrs:{"data":_vm.dashboardChartData,"options":{
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },"type":"bar"}})],1),_c('BaseBox',{staticClass:"grid gap-4"},[_c('BaseHeading',{staticClass:"text-lg font-bold",attrs:{"level":2}},[_vm._v(" Lista de fichas técnicas generadas ")]),_c('BaseBox',{staticClass:"bg-white dark:bg-gray-800 shadow rounded-md overflow-hidden px-6 py-8"},[_c('BaseTable',{attrs:{"pagination-options":{
          enabled: true,
        },"sort-options":{
          enabled: false,
        },"rows":_vm.rows,"columns":_vm.columns},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
        var column = ref.column;
        var formattedRow = ref.formattedRow;
        var row = ref.row;
return [(column.field === 'quoteId')?_c('span',[_c('BaseButton',{attrs:{"tone":"secondary","loading":_vm.currentQuoteIdBeingDownloaded === row.quoteId},on:{"click":function($event){return _vm.downloadQuoteFile(row)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" Descargar ")]},proxy:true},{key:"loading-text",fn:function(){return [_vm._v(" Descargando... ")]},proxy:true}],null,true)})],1):(column.field === 'creationDate')?_c('span',[_c('BaseDate',{attrs:{"date":row.creationDate}})],1):_c('span',[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }