


































































































import communique from '@/notification'
import {
  DashboardSelectModel,
  dashboardService,
  DashboardViewModel,
  QuoteViewModel,
} from '@/services/modules/dashboard'
import SiteChart from '@/components/SiteChart.vue'
import NumberAnimated from '@/components/number/NumberAnimated.vue'
import { VueGoodTableColumn } from '@/setup/vue-good-table'
import { useTechnologyQuote } from '@/composition/useTechnology'
import { ChartData, ChartDataset } from 'chart.js'
import { startOfMonth, startOfWeek, subMonths } from 'date-fns'
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api'

interface DashboardDateFilterOption {
  key: string
  title: string
  startingDate: Date | null
  endingDate: Date | null
}

export default defineComponent({
  name: 'Dashboard',

  components: {
    SiteChart,
    NumberAnimated,
  },

  setup() {
    const dashboardModel = reactive<DashboardSelectModel>({
      startingDate: null,
      endingDate: null,
    })

    const dashboardViewModel = reactive<DashboardViewModel>({
      totalViewModel: null,
      quoteTotalList: null,
      quoteList: null,
    })

    const isLoading = ref(false)

    onMounted(() => {
      getDashboard()
    })

    const dashboardCards = computed(() => {
      if (!dashboardViewModel.totalViewModel) return []

      return [
        {
          key: 'quoteTotal',
          icon: 'file-pdf',
          title: 'Fichas técnicas generadas',
          value: dashboardViewModel.totalViewModel.quoteTotal,
        },
        {
          key: 'contactTotal',
          icon: 'address-book',
          title: 'Total de contactos',
          value: dashboardViewModel.totalViewModel.contactTotal,
        },
        {
          key: 'manometerValue',
          icon: 'water',
          title: 'Agua en manómetro',
          value: dashboardViewModel.totalViewModel.manometerValue,
        },
        {
          key: 'manometerIncrementBy',
          icon: 'tint',
          title: 'Incremento diario de agua',
          value: dashboardViewModel.totalViewModel.manometerIncrementBy,
        },
      ]
    })

    const dashboardChartData = computed<ChartData>(() => {
      const labels: string[] = []
      const dataset: ChartDataset = {
        label: 'Cotizaciones',
        data: [],
        backgroundColor: ['rgba(1, 165, 177, 0.5)'],
        borderColor: ['rgba(1, 165, 177, 1)'],
        borderWidth: 1,
      }

      const list = dashboardViewModel.quoteTotalList || []

      for (const item of list) {
        labels.push(item.name)
        dataset.data.push(item.total)
      }

      return {
        labels,
        datasets: [dataset],
      }
    })

    async function getDashboard() {
      try {
        isLoading.value = true

        const {
          data: { totalViewModel, quoteTotalList, quoteList },
        } = await dashboardService.get(dashboardModel)

        dashboardViewModel.totalViewModel = totalViewModel
        dashboardViewModel.quoteTotalList = quoteTotalList
        dashboardViewModel.quoteList = quoteList
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message:
            'Ocurrió un error al intentar obtener la información del tablero de control.',
        })
      } finally {
        isLoading.value = false
      }
    }

    watch(
      () => dashboardModel,
      () => {
        getDashboard()
      },
      { deep: true }
    )

    const rows = computed(() => dashboardViewModel.quoteList || [])

    const columns: VueGoodTableColumn<QuoteViewModel>[] = [
      {
        label: 'Email',
        field: 'email',
      },
      {
        label: 'Tecnología propuesta',
        field: 'proposedTechnology',
      },
      {
        label: 'Fecha de creación',
        field: 'creationDate',
      },
      {
        label: 'Ficha técnica',
        field: 'quoteId',
      },
    ]

    const currentQuoteIdBeingDownloaded = ref('')

    async function downloadQuoteFile(quote: QuoteViewModel) {
      try {
        currentQuoteIdBeingDownloaded.value = quote.quoteId

        await useTechnologyQuote({
          quoteId: quote.quoteId,
          proposedTechnology: quote.proposedTechnology,
          wasteWaterFlowToBeTreated: quote.wasteWaterFlowToBeTreated,
          biochemicalOxygenDemandConcentration:
            quote.biochemicalOxygenDemandConcentration,
          complianceWithTheStandard: quote.complianceWithTheStandard,
          requiredArea: quote.requiredArea,
          investmentBaseBudget: quote.investmentBaseBudget,
          waterFlowToBeTreatedPerDay: quote.waterFlowToBeTreatedPerDay,
          complianceWithStandard: quote.complianceWithStandard,
          fieldOfBusiness: quote.fieldOfBusiness,
          residentialType: quote.residentialType,
          rangeOfPeople: quote.rangeOfPeople,
          commerceType: quote.commerceType,
          waterType: quote.waterType,
          sectorType: quote.sectorType,
          typeOfBird: quote.typeOfBird,
          animalQuantity: quote.animalQuantity,
          tonsOfChickensPerDay: quote.tonsOfChickensPerDay,
          siteType: quote.siteType,
          animalType: quote.animalType,
          numberOfShifts: quote.numberOfShifts,
          shiftEmployerAmount: quote.shiftEmployerAmount,
          waterConsumptionPerDay: quote.waterConsumptionPerDay,
          email: null,
        })
      } catch (e) {
        communique.dispatch({
          variant: 'error',
          message: 'Ocurrió un error al tratar de descargar la ficha técnica.',
        })
      } finally {
        currentQuoteIdBeingDownloaded.value = ''
      }
    }

    const today = new Date()

    const defaultDashboardFilterOption = {
      key: 'all',
      title: 'Todo',
      startingDate: null,
      endingDate: null,
    }

    const dashboardDateFilterOptions: DashboardDateFilterOption[] = [
      {
        key: '3-month',
        title: 'Hace 3 meses',
        startingDate: subMonths(today, 3),
        endingDate: null,
      },
      {
        key: 'month',
        title: 'Este mes',
        startingDate: startOfMonth(today),
        endingDate: null,
      },
      {
        key: 'week',
        title: 'Esta semana',
        startingDate: startOfWeek(today),
        endingDate: null,
      },
      defaultDashboardFilterOption,
    ]

    const dashbaordFilterOption = ref<DashboardDateFilterOption | null>(
      defaultDashboardFilterOption
    )

    watch(
      () => dashbaordFilterOption.value,
      (newValue) => {
        dashboardModel.startingDate =
          newValue && newValue.startingDate
            ? newValue.startingDate.toISOString()
            : null
        dashboardModel.endingDate =
          newValue && newValue.endingDate
            ? newValue.endingDate.toISOString()
            : null
      }
    )

    return {
      dashboardModel,
      dashboardViewModel,
      isLoading,
      dashboardCards,
      dashboardChartData,
      rows,
      columns,
      downloadQuoteFile,
      currentQuoteIdBeingDownloaded,
      dashboardDateFilterOptions,
      dashbaordFilterOption,
    }
  },
})
